import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../constants/env";
import setAuthToken from "../utils/setAuthToken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import truncateWallet from "../utils/truncateWallet";

const UserContextTemplate = {
  userInfo: {
    walletAddress: null,
    walletConnected: false,
    userName: "none",
    userAvatar: "",
    totalspent: 0,
    totalRevenue: 0,
    totalVolume: 0,
    transactionCount: 0,
    sellingRoyaltyFee: 0,
    sellingPlatformFee: 0,
    Pnlvalid: false,
    currentRoundId: null,
    updatePnl: false,
    pnlTime: null,
    isWinner: false,
    isLogin: false,
    twitterPageStatus: false,
    discordServerStatus: false,
    isTwitterConnected: false,
    isDiscordConnected: false,
  },
  walletConnect: () => {},
  logout: () => {},
  authWithDiscord: () => {},
  loginWithDiscord: () => {},
  authWithTwitter: () => {},
  loginWithTwitter: () => {},
  reset: () => {},
  updateUser: () => {},
  getMyInfo: () => {},
  setUserInfo: () => {},
};

const UserContext = React.createContext(UserContextTemplate);

const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({
    walletAddress: null,
    walletConnected: false,
    userName: "none",
    userAvatar: "",
    totalspent: 0,
    totalRevenue: 0,
    totalVolume: 0,
    transactionCount: 0,
    sellingRoyaltyFee: 0,
    sellingPlatformFee: 0,
    Pnlvalid: false,
    currentRoundId: null,
    updatePnl: false,
    pnlTime: null,
    isWinner: false,
    isLogin: false,
    twitterPageStatus: false,
    discordServerStatus: false,
    isTwitterConnected: false,
    isDiscordConnected: false,
  });
  const [showFAQmodalLeaderboard, setShowFAQmodalLeaderboard] = useState(false);
  const [signUpModalShow, setSignUpModalShow] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-error",
    });
  };

  useEffect(() => {
    const jwt = localStorage.getItem("pnl_token");
    const userID = localStorage.getItem("pnl_id");
    if (jwt && userID) getMyInfo();
  }, []);

  const authWithDiscord = async () => {
    let result = await axios.post(`${SERVER_URL}/users/authwithdiscord/`, {
      walletAddress: userInfo.walletAddress,
    });
    if (result.data.status) location.href = result.data.data;
  };
  const logout = async () => {
    localStorage.removeItem("id");
    localStorage.removeItem("pnl_token");
    setUserInfo({
      walletAddress: null,
      walletConnected: false,
      userName: "none",
      userAvatar: "",
      islogin: false,
    });
    localStorage.clear();
    notifySuccess("Successfully logged out!");
  };
  const loginWithDiscord = async (code, walletAddress) => {
    let result = await axios.post(`${SERVER_URL}/users/loginwithdiscord/`, {
      code,
      walletAddress,
    });

    if (result.data.status) {
      setUserInfo((prev) => ({
        ...prev,
        walletAddress: result.data.user.walletAddress,
        walletConnected: true,
        isLogin: result.data.user.isLogin,
        totalspent: result.data.user.totalspent,
        totalRevenue: result.data.user.totalRevenue,
        totalVolume: result.data.user.totalVolume,
        transactionCount: result.data.user.transactionCount,
        sellingRoyaltyFee: result.data.user.sellingRoyaltyFee,
        sellingPlatformFee: result.data.user.sellingPlatformFee,
        Pnlvalid: result.data.user.Pnlvalid,
        currentRoundId: result.data.user.currentRoundId,
        updatePnl: result.data.user.updatePnl,
        pnlTime: result.data.user.pnlTime,
        isWinner: result.data.user.isWinner,
        twitterPageStatus: result.data.user.is_Twitter_Page_Follwed,
        discordServerStatus: result.data.user.is_Discord_Server_Joined,
        isTwitterConnected: result.data.user.is_Twitter_Connected,
        isDiscordConnected: result.data.user.is_Discord_Connected,
      }));
      if(!result.data.user.signed){
        setSignUpModalShow(true);
      }
      localStorage.setItem("pnl_token", result.data.user.pnlToken);
      localStorage.setItem("pnl_id", result.data.user._id);
    }
    return result.data;
  };

  const authWithTwitter = async () => {
    let result = await axios.post(`${SERVER_URL}/users/authwithtwitter/`, {
      walletAddress: userInfo.walletAddress,
    });
    if (result.data.status) {
      localStorage.setItem("codeVerifier", result.data.data.codeVerifier);
      location.href = result.data.data.url;
    }
  };

  const loginWithTwitter = async (code, walletAddress, codeVerifier) => {
    let result = await axios.post(`${SERVER_URL}/users/loginwithtwitter/`, {
      code,
      walletAddress,
      codeVerifier,
    });
    if (result.data.status) {
      setUserInfo({
        walletAddress: result.data.user.walletAddress,
        walletConnected: true,
        isLogin: true,
        totalspent: result.data.user.totalspent,
        totalRevenue: result.data.user.totalRevenue,
        totalVolume: result.data.user.totalVolume,
        transactionCount: result.data.user.transactionCount,
        sellingRoyaltyFee: result.data.user.sellingRoyaltyFee,
        sellingPlatformFee: result.data.user.sellingPlatformFee,
        Pnlvalid: result.data.user.Pnlvalid,
        currentRoundId: result.data.user.currentRoundId,
        updatePnl: result.data.user.updatePnl,
        pnlTime: result.data.user.pnlTime,
        isWinner: result.data.user.isWinner,
        twitterPageStatus: result.data.user.is_Twitter_Page_Follwed,
        discordServerStatus: result.data.user.is_Discord_Server_Joined,
        isTwitterConnected: result.data.user.is_Twitter_Connected,
        isDiscordConnected: result.data.user.is_Discord_Connected,
      });
      if(!result.data.user.signed){
        setSignUpModalShow(true);
      }
      localStorage.setItem("pnl_token", result.data.pnlToken);
      localStorage.setItem("pnl_id", result.data.user._id);
    }
    return result.data;
  };

  const connectWallet = async (Address) => {
    try {
      setUserInfo((currValue) => ({
        ...currValue,
        walletAddress: Address,
        walletConnected: true,
      }));
      localStorage.setItem("address", Address);

      let result = await axios.post(`${SERVER_URL}/users/loginwithwallet/`, {
        walletAddress: Address,
      });
      if (result.data.status) {
        setUserInfo((prev) => ({
          ...prev,
          isLogin: result.data.user.isLogin,
          totalspent: result.data.user.totalspent,
          totalRevenue: result.data.user.totalRevenue,
          totalVolume: result.data.user.totalVolume,
          transactionCount: result.data.user.transacationCount, // Fixed typo
          sellingRoyaltyFee: result.data.user.sellingRoyaltyFee,
          sellingPlatformFee: result.data.user.sellingplatformFee, // Fixed case
          Pnlvalid: result.data.user.Pnlvalid, // Fixed case
          currentRoundId: result.data.user.current_round_id, // Fixed case
          updatePnl: result.data.user.updatepnl, // Fixed case
          pnlTime: result.data.user.pnlTime,
          isWinner: result.data.user.isWinner,
          twitterPageStatus: result.data.user.is_Twitter_Page_Follwed, // Fixed case
          discordServerStatus: result.data.user.is_Discord_Server_Joined, // Fixed case
          isTwitterConnected: result.data.user.is_Twitter_Connected, // Fixed case
          isDiscordConnected: result.data.user.is_Discord_Connected, // Fixed case
        }));
        localStorage.setItem("pnl_token", result.data.jwt);
        localStorage.setItem("pnl_id", result.data.user._id);
        if(!result.data.user.signed){
          setSignUpModalShow(true);
        }
        return true;
      } else {
        notifyError(result.data.message);
        return false;
      }
    } catch (error) {
      notifyError(error);
      return false;
    }
  };

  const getMyInfo = async () => {
    const userId = localStorage.getItem("pnl_id");

    if (!userId) return false;

    let result = await axios
      .get(`${SERVER_URL}/users/userid/${userId}`)
      .then((result) => {
        if (result.data.status) {
          setUserInfo((currValue) => ({
            ...currValue,
            isLogin: true,
            walletAddress: result.data.user.walletAddress,
            totalspent: result.data.user.totalspent,
            totalRevenue: result.data.user.totalRevenue,
            totalVolume: result.data.user.totalVolume,
            transactionCount: result.data.user.transactionCount,
            sellingRoyaltyFee: result.data.user.sellingRoyaltyFee,
            sellingPlatformFee: result.data.user.sellingPlatformFee,
            Pnlvalid: result.data.user.Pnlvalid,
            currentRoundId: result.data.user.currentRoundId,
            updatePnl: result.data.user.updatePnl,
            pnlTime: result.data.user.pnlTime,
            isWinner: result.data.user.isWinner,
            twitterPageStatus: result.data.user.is_Twitter_Page_Follwed,
            discordServerStatus: result.data.user.is_Discord_Server_Joined,
            isTwitterConnected: result.data.user.is_Twitter_Connected,
            isDiscordConnected: result.data.user.is_Discord_Connected,
          }));
          if(!result.data.user.signed){
            setSignUpModalShow(true);
          }
        }
        
        return result.data.status;
      })
      .catch((error) => {
        notifyError(error);
        return false;
      });

    return result;
  };

  return (
    <UserContext.Provider
      value={{
        userInfo,
        connectWallet,
        authWithDiscord,
        loginWithDiscord,
        authWithTwitter,
        loginWithTwitter,
        setUserInfo,
        getMyInfo,
        showFAQmodalLeaderboard,
        setShowFAQmodalLeaderboard,
        setSignUpModalShow,
        signUpModalShow,
        notificationList,
        showNotificationPopup,
        logout,
      }}
    >
      <ToastContainer />
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
export default UserProvider;
