import { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
import TopWinner from "../topWinner";
import "./index.scss";
import LastWeekResult from "../LastWeekResult";
import ThisWeekResult from "../ThisWeekResult";

const Termresult = ({ thisWeek, lastWeek, allTime }) => {
  const leaderboard = useContext(LeaderboardContext);

  useEffect(() => {
    leaderboard.getWeekResult();
  }, []);

  const lastWeekResult = useCallback(() => {
    let result = [];

    if (Array.isArray(leaderboard.lastWeekResult)) {
      result = leaderboard.lastWeekResult.map((el) => {
        return {
          userName: el.attributes.walletAddress,
          gain: el.attributes.gain,
          lose: el.attributes.totalspent,
          profit: el.attributes.profit,
        };
      });

      result.sort(function (a, b) {
        return a.gain - a.totalRevenue - (b.gain - b.totalRevenue);
      });

      result.reverse();
    }

    return result;
  }, [leaderboard.lastWeekResult]);

  const currentWeekResult = useCallback(() => {
    let result = [];

    if (Array.isArray(leaderboard.currentWeekResult)) {
      result = leaderboard.currentWeekResult?.user_list.map((el) => {
        return {
          userName: el.attributes.walletAddress,
          gain: el.attributes.gain,
          lose: el.attributes.lose,
          profit: el.attributes.profit,
        };
      });

      result.sort(function (a, b) {
        return parseFloat(a.totalRevenue) - parseFloat(b.totalRevenue);
      });

      result.reverse();
    }

    return result;
  }, [leaderboard.currentWeekResult]);

  return (
    <>
      {thisWeek && (
        <ThisWeekResult
          result={leaderboard.currentWeekResult}
          loading={leaderboard.loadingTW}
          winner={leaderboard.currentWeekEns}
        />
      )}
      {lastWeek && (
        <LastWeekResult
          result={leaderboard.lastWeekResult}
          loading={leaderboard.loadingLW}
          winner={leaderboard.lastWeekEns}
        />
      )}
      {allTime && (
        <TopWinner
          weektype={"alltime"}
          result={leaderboard.topResult}
          loading={leaderboard.loadingAT}
        />
      )}
    </>
  );
};

export default Termresult;
