import React, { useState, useEffect, useMemo } from "react";
import {
  AlltimeResult,
  ApiLastWeekResult,
  thisweekResult,
} from "./leaderboardservice";
import { useAccount } from "wagmi";
const LeaderboardContextTemplate = {
  lastWeekResult: [{}],
  currentWeekResult: [{}],
  topResult: [{}],
  getWeekResult: () => {},
  getRoundInfo: () => {},
};

const LeaderboardContext = React.createContext(LeaderboardContextTemplate);

const LeaderboardProvider = ({ children }) => {
  const { address } = useAccount();
  const [lastWeekResult, setLastWeekResult] = useState([]);
  const [currentWeekResult, setCurrentWeekResult] = useState({});
  const [topResult, setTopResult] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loadingAT, setLoadingAT] = useState(false);
  const [loadingLW, setLoadingLW] = useState(false);
  const [loadingTW, setLoadingTW] = useState(false);
  const [alreadyWon, setAlreadyWon] = useState(false);
  const [winners, setWinners] = useState([]);
  const [currentWeekEns, setCurrentWeekEns] = useState([]);
  const [lastWeekEns, setLastWeekEns] = useState([]);
  const [winnersEns, setWinnersEns] = useState([]);

  const [winnerLeaderboard, setWinnerLeaderboard] = useState(true);
  const [loserLeaderboard, setLoserLeaderboard] = useState(false);
  const [roundInfo, setRoundInfo] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [userlostamount, setUserLostAmount] = useState({
    totalRevenue: 0,
    iswinner: false,
  });

  const [winnerCount, setWinnerCount] = useState(0);

  useEffect(() => {
    getWeekResult();
  }, []);

  const getWeekResult = async () => {
    //default page will be 1
    await getWeekResultAT(1);
    await getWeekResultLW(1);
    await getWeekResultTW(1);
  };

  const getWeekResultTW = async (page_number) => {
    try {
      setLoadingTW(true);
      const response = await thisweekResult({ page: page_number });
      setRoundInfo(response.data.current_round);
      setCurrentWeekResult(response.data);
      setWinnerCount(response.data.winner_count);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTW(false);
    }
  };

  const getWeekResultLW = async (page_number) => {
    try {
      setLoadingLW(true);
      const response = await ApiLastWeekResult({ page: page_number });
      setLastWeekResult(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingLW(false);
    }
  };

  const getWeekResultAT = async (page_no) => {
    try {
      setLoadingAT(true);
      const response = await AlltimeResult({ page: page_no });
      response?.data.all_time_users?.map((winner) => {
        const wallet = address?.toLowerCase();
        const winnerAddress = winner.walletAddress.toLowerCase();
        if (wallet == winnerAddress) {
          setAlreadyWon(true);
          setShowModal(true);
        }
      });

      setTopResult(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAT(false);
    }
  };

  const filteredTopResult = useMemo(() => {
    if (searchInput.length > 0 && Array.isArray(topResult)) {
      // if(searchInput[0]!= 0) {
      //   return winnersEns.filter((result) => {
      //     return result.match(searchInput);
      //   });
      // } else {
      return topResult.filter((result) => {
        return result?.walletAddress.match(searchInput);
      });
      // }
    } else {
      return topResult;
    }
  }, [searchInput, topResult]);

  const filteredLastWeekResult = useMemo(() => {
    if (searchInput.length > 0 && Array.isArray(lastWeekResult)) {
      // if(searchInput[0]!= 0) {
      //   return lastWeekEns.filter((result) => {
      //     return result.match(searchInput);
      //   });
      // } else {
      return lastWeekResult.filter((result) => {
        return result.walletAddress.match(searchInput);
      });
      // }
    } else {
      return lastWeekResult;
    }
  }, [searchInput, lastWeekResult]);

  const filteredCurrentWeekResult = useMemo(() => {
    if (searchInput.length > 0 && Array.isArray(currentWeekResult)) {
      return currentWeekResult?.user_list.filter((result) => {
        const walletAddressMatch =
          result.walletAddress && result.walletAddress.match(searchInput);
        const ensMatch = result.ens && result.ens.match(searchInput);
        return walletAddressMatch || ensMatch;
      });
    } else {
      return currentWeekResult;
    }
  }, [searchInput, currentWeekResult]);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(escapeRegExp(e.target.value.toLowerCase()));
  };

  const handleInputPaste = (e) => {
    const value = e.clipboardData.getData("Text");
    setSearchInput(value);
  };

  return (
    <LeaderboardContext.Provider
      value={{
        topResult,
        lastWeekResult,
        currentWeekResult,
        getWeekResult,
        getWeekResultTW,
        getWeekResultAT,
        getWeekResultLW,
        searchInput,
        setSearchInput,
        handleSearch,
        filteredTopResult,
        filteredLastWeekResult,
        filteredCurrentWeekResult,
        loadingAT,
        loadingLW,
        loadingTW,
        setAlreadyWon,
        alreadyWon,
        winners,
        handleInputPaste,
        showModal,
        setShowModal,
        winnersEns,
        currentWeekEns,
        lastWeekEns,
        winnerLeaderboard,
        setWinnerLeaderboard,
        loserLeaderboard,
        setLoserLeaderboard,
        userlostamount,
        setUserLostAmount,
        setCurrentWeekResult,
        roundInfo,

        winnerCount,
        setWinnerCount,
      }}
    >
      {children}
    </LeaderboardContext.Provider>
  );
};

export { LeaderboardContext };
export default LeaderboardProvider;
