import React, { useState, useContext, useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SERVER_URL } from "../../constants/env";
import { UserContext } from "../../provider/userprovider";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

let mounted = true;

const TwitterLoading = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [redirect, setRedirect] = useState(false);
  const user = useContext(UserContext);

  // Toast notification success
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  // Toast notification error
  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-error",
    });
  };

  useEffect(() => {
    if (mounted) loginWithTwitter();

    mounted = false;
    return () => (mounted = false);
  }, []);

  const loginWithTwitter = async () => {
    const codeVerifier = localStorage.getItem("codeVerifier");
    try {
      const result = await user
        .loginWithTwitter(
          searchParams.get("code"),
          searchParams.get("state"),
          codeVerifier
        )
        .then((res) => {
          if (res.status) {
            setRedirect(true);
          } else {
            //console.log("res:=" + res);
            notifyError(`${res.message}.`);
            setTimeout(function () {
              setRedirect(true);
            }, 3000);
          }
        });
    } catch (e) {
      notifyError("Error In Request.Please try again later" + e.message);

      setTimeout(function () {
        setRedirect(true);
      }, 5000);
    }
    /*
    if (!result.status) not(result.message);
    setRedirect(true);
    */
  };
  return (
    <div className="App" style={{ background: "rgba(0, 0, 0, 0.85)" }}>
      {redirect ? (
        <Navigate to="/" />
      ) : (
        <div className="full-content content-center">Authorizing...</div>
      )}
    </div>
  );
};

export default TwitterLoading;
