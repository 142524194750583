import { http } from "../services";
export const thisweekResult = (queryData) => {
  return http.get("/leaderboard/thisweek", {
    params: queryData,
  });
};
export const ApiLastWeekResult = (queryData) => {
  return http.get("/leaderboard/lastweek", {
    params: queryData,
  });
};
export const AlltimeResult = (queryData) => {
  return http.get("/leaderboard/alltime", { params: queryData });
};
