import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.scss";

import LeaderboardProvider from "./provider/leaderboard";
import "react-toastify/dist/ReactToastify.css";
// Pages
import LeaderBoard from "./pages/leaderboard";
import NavBar from "./components/NavBar/NavBar";
import Error from "./pages/ErrorPage/Error";
import DiscordLoading from "./pages/discordLoading";
import TwitterLoading from "./pages/twitterLoading";
import UserProvider from './provider/userprovider';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

const config = getDefaultConfig({
  appName: 'flip and failures',
  projectId: '4fd1ca99d364ee964d86ea44323c2a05',
  chains: [mainnet],
  ssr: true, // If your dApp uses server side rendering (SSR)
});
const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
      <RainbowKitProvider>
        <UserProvider>
        <LeaderboardProvider>
          <ToastContainer />
          <NavBar />
          <Routes>
            <Route path="/*" element={<Error />} />
            <Route path="/discord-auth" element={<DiscordLoading />} />
            <Route path="/twitter-auth" element={<TwitterLoading />} />
            <Route path="/" element={<LeaderBoard />} />
          </Routes>
        </LeaderboardProvider>
        </UserProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
