import { useState, useContext, useEffect, useRef } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
import { useMediaQuery } from "react-responsive";
import { useAccount } from "wagmi";

// Assets
import { ReactComponent as SearchIcon } from "../../assets/media/icons/SearchIcon.svg";

// Components
import Loader from "../../components/Loader";
import Header from "../../components/header";
import Footer from "../../components/Footer/Footer";
import Termresult from "../../components/termresult";
import PLTrackerNoWallet from "./PLTrackerNoWallet";
import WinPopup from "../../components/WinPopup/WinPopup";
import { UserContext } from "../../provider/userprovider";
import HourCountdownTimer from "../../components/CountdownTimer/HourCountDownTimer";
import Notification from "../../components/Notification/Notification";
import Modal from '../../components/modal'
// Styles
import "./index.scss";
import Pagination from "./Pagination";
import truncateWallet  from "../../utils/truncateWallet";
const LeaderBoard = (props) => {
  const { address, isConnected } = useAccount();
  const user = useContext(UserContext);
  const { setSignUpModalShow, signUpModalShow } = useContext(UserContext);
  const isTabletDevice = useMediaQuery({ query: "(max-width: 889px)" });
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const searchBarRef = useRef(null);
  const searchBarRefMobile = useRef(null);

  const [showWinnerPopup, setShowWinnerPopup] = useState(false);
  // This is tweak, please change to false when done.
  // Waiting for functionality for it.

  const [thisWeek, setThisWeek] = useState(true);
  const [lastWeek, setLastWeek] = useState(false);
  const [allTime, setAllTime] = useState(false);

  const {
    handleSearch,
    handleInputPaste,
    setSearchInput,
    loadingTW,
    userlostamount,
    winnerCount,
  } = useContext(LeaderboardContext);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useEffect(() => {
    document.title = "Bear & Bull Game • P&L Tracker";
  }, []);

  useEffect(() => {
    setIsNotificationOpen(userlostamount.iswinner);
  }, [userlostamount]);

  useEffect(() => {
    const handleReset = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        searchBarRef.current.value = "";
        searchBarRefMobile.current.value = "";
        setSearchInput("");
      }
    };

    document.addEventListener("mousedown", handleReset);

    return () => {
      document.removeEventListener("mousedown", handleReset);
    };
  }, []);

  const handleBackspace = (event) => {
    // Backspace delete
    if (event.keyCode === 8) {
      searchBarRef.current.value = "";
      searchBarRefMobile.current.value = "";
      setSearchInput("");
    }
  };

  useEffect(() => {
    // Add event listener to document to listen for keydown event
    window.addEventListener("keydown", handleBackspace);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleBackspace);
    };
  }, []);

  // Chart navigation buttons
  const chartButtonHandler = (pageName) => {
    switch (pageName) {
      case "thisWeek":
        setThisWeek(true);
        setLastWeek(false);
        setAllTime(false);
        break;

      case "lastWeek":
        setThisWeek(false);
        setLastWeek(true);
        setAllTime(false);
        break;

      case "allTime":
        setThisWeek(false);
        setLastWeek(false);
        setAllTime(true);
        break;

      default:
        break;
    }
  };

  return isConnected ? (
    <>
      {isNotificationOpen && (
        <Notification
          setIsNotificationOpen={setIsNotificationOpen}
          totalRevenue={userlostamount.totalRevenue}
        />
      )}
      {loadingTW && <Loader />}
      {isTabletDevice && <Header />}
      {/* Mobile search */}
      <Modal
        visible={signUpModalShow}
        close={() => setSignUpModalShow(false)}
        title="SIGN UP"
      >
        <div className="signup-walletadress">
          <div className="warning-text">
            <p>
              *In order to participate verify your wallet address and social
              handle.
            </p>
            {/*
            {user.userInfo.TwitterPageStatus && (
              <p>
                <span style={{ backgroundColor: "red" }}>
                  To connect with us on X, ensure you're following us on X.
                </span>
                <a href="https://x.com/CultsNFT">@CultsNFT</a>
              </p>
            )}
            {user.userInfo.DiscordServerStatus && (
              <p>
                <span style={{ backgroundColor: "red" }}>
                  To connect with us on discord, ensure you're member.
                </span>
                <a href="https://Discord.gg/cultsnft">@CultsNFT</a>
              </p>
            )}
            */}
          </div>
          <div className="modal-wallet-wrapper">
            <div className="wallet-text">Wallet:</div>
            <div className="wallet-adress">
              {truncateWallet(user.userInfo.walletAddress)}
            </div>
          </div>
        </div>

        <div className="signup-buttons-wrapper">
          {user.userInfo.isTwitterConnected == false? (
            <div
              className="login-twitter"
              onClick={() => user.authWithTwitter()}
            >
              <span>Connect with X</span>
            </div>
          ):( <div
            className="login-twitter"
            onClick={() => user.authWithTwitter()}
          >
            <span   style={{ color: "green" ,cursor: "pointer" }}>Connected with X</span>
          </div>)}

          {user.userInfo.isDiscordConnected == false ? (
            <div
              className="login-discord"
              onClick={() => user.authWithDiscord()}
            >
              <span>Connect with Discord</span>
            </div>
          ):(
            <div
              className="login-discord"
              onClick={() => user.authWithDiscord()}
            >
              <span  style={{ color: "green" ,cursor: "pointer" }}>Connected with Discord</span>
            </div>
          )}
        </div>

        <div className="signup-buttons-wrapper">
          {user.userInfo.isTwitterConnected== false && (
            <div className="login-twitter" onClick={() => openTwitterLink()}>
              <span className="twitterlink">Follow Us On X</span>
            </div>
          )}

          {user.userInfo.isDiscordConnected== false && (
            <div className="login-discord" onClick={() => openDiscordLink()}>
              <span className="discordlink">Join Us On Discord</span>
            </div>
          )}
        </div>
      </Modal>
      <div className="search-mobile">
        <SearchIcon className="search-icon" />
        <input
          ref={searchBarRefMobile}
          type="text"
          className="leaderboard-search"
          placeholder="SEARCH ADDRESS"
          onChange={(e) => handleSearch(e)}
          onPaste={(e) => handleInputPaste(e)}
        />
      </div>
      {/* Mobile search */}
      <div className="leaderboard">
        <div className="leaderboard-page-top">
          <div className="left-side">
            <div className="leaderboard-chart-wrapper">
              <div className="leaderboard-chart-top-buttons">
                <div className="leaderboard-chart-top-left">
                  <div
                    className={
                      thisWeek ? "this-week-button active" : "this-week-button"
                    }
                    onClick={() => chartButtonHandler("thisWeek")}
                  >
                    THIS WEEK
                  </div>
                  <div
                    className={
                      lastWeek ? "last-week-button active" : "last-week-button"
                    }
                    onClick={() => chartButtonHandler("lastWeek")}
                  >
                    LAST WEEK
                  </div>
                  <div
                    className={
                      allTime ? "all-time-button active" : "all-time-button"
                    }
                    onClick={() => chartButtonHandler("allTime")}
                  >
                    ALL TIME
                  </div>
                </div>

                <div className="leaderboard-chart-top-right">
                  <SearchIcon className="search-icon" />
                  <input
                    ref={searchBarRef}
                    type="text"
                    className="leaderboard-search"
                    placeholder="SEARCH ADDRESS"
                    onChange={(e) => handleSearch(e)}
                    onPaste={(e) => handleInputPaste(e)}
                  />
                </div>
              </div>
              <div className="leaderboard-top-table-headers">
                <div className="leaderboard-top-table-header-left">
                  <div className="leaderboard-top-table-header-rank">RANK</div>
                  <div className="leaderboard-top-table-header-wallet-address">
                    {!isSmallDevice && "WALLET"} ADDRESS
                  </div>
                </div>

                <div className="leaderboard-top-table-header-right">
                  <div className="leaderboard-top-table-header-lost-gained-amount">
                    Total Revenue
                  </div>
                </div>
              </div>
              <div className="leaderboard-list">
                <Termresult
                  thisWeek={thisWeek}
                  lastWeek={lastWeek}
                  allTime={allTime}
                />
              </div>
            </div>
          </div>

          <div className="right-side">
            {!isTabletDevice && <Header />}
            <div className="region-filters-box">
              <div className="rules-eth-sim">THIS WEEK'S TIMER</div>
              <div className="logintojoin-leaderboard">
                <HourCountdownTimer />
              </div>
            </div>
            <div className="leaderboard-warning">
              CURRENT WEEK WINNERS COUNT: {winnerCount}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PLTrackerNoWallet />
  );
};

export default LeaderBoard;
