import React, { useEffect, useState, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../../provider/userprovider";
import "./NavBar.scss";
// Assets
import LogoGold from "../../assets/media/images/bearbull.png";
import { ReactComponent as HamburgerIcon } from "../../assets/media/icons/hamburgerMenu.svg";
import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
import truncateWallet from "../../utils/truncateWallet";
import WinPopup from "../WinPopup/WinPopup";
import { LeaderboardContext } from "../../provider/leaderboard";
// Web3 utils
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect } from "wagmi";
const NavBar = () => {
  const { openConnectModal } = useConnectModal();
  const { userlostamount, setUserLostAmount, setCurrentWeekResult } =
    useContext(LeaderboardContext);
    const user = useContext(UserContext);
    const { setSignUpModalShow } = useContext(UserContext);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] = useState(false);

  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [userdata, setUserdata] = useState({});
  const [loading, setLoading] = useState(false);

  const handleConnectWallet = async () => {
    localStorage.clear()
    openConnectModal()
  };
  const handleDisconnectWallet = async () => {
    try {
       disconnect();
      user.logout();
      localStorage.clear();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isConnected) {
      calculateWallet(address);
    }
  }, [address, isConnected]);
  async function calculateWallet() {
    const jwt = localStorage.getItem("pnl_token");
    if (!jwt) {
      const api_res = await user.connectWallet(address);
      console.log("api_res", api_res);
      if (api_res) {
        setSignUpModalShow(true);
      } else {
         disconnect();
      }
    }
  }
  return (
    <>
      {/* Mobile NavBar */}
      <div
        className={
          isMobileSideMenuOpen ? "mobile-side-menu active" : "mobile-side-menu"
        }
      >
        <div className="close-btn-wrapper">
          <div
            onClick={() => setIsMobileSideMenuOpen(false)}
            className="close-btn"
          >
            <CloseIcon />
          </div>
        </div>
        <a href="https://bearandbull.game/" rel="noreferrer" target={"_blank"}>
          HOME
        </a>
        <a
          href="https://bearandbull.game/whitelist-quests"
          rel="noreferrer"
          target={"_blank"}
        >
          WHITELIST QUESTS
        </a>
      </div>
      {/* Mobile NavBar */}

      <div className="navbar">
        <div className="navbar-top">
          <div className="nav-left-side">
            <a
              href="https://bearandbull.game/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LogoGold} alt="" />
            </a>

            <a
              href="https://bearandbull.game/"
              rel="noreferrer"
              target={"_blank"}
            >
              HOME
            </a>
            {/* <a
              href="https://barbearians-front-end.pages.dev/missions"
              rel="noreferrer"
              target={"_blank"}
            >
              MISSIONS
            </a> */}
            <a
              href="https://bearandbull.game/whitelist-quests"
              rel="noreferrer"
              target={"_blank"}
            >
              WHITELIST QUESTS
            </a>
            {/* <a
              href="https://barbearians-front-end.pages.dev/theallowlist"
              rel="noreferrer"
              target={"_blank"}
            >
              THE ALLOWLIST
            </a> */}
            {/* <a
                  href="https://barbearians-front-end.pages.dev/WHITEpaper"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  WHITEPAPER
                </a> */}
          </div>

          <div className="nav-right-side">
            {isConnected ? (
              <div onClick={handleDisconnectWallet} className="login-button">
                <div className="logout">{truncateWallet(address, 12)}</div>
              </div>
            ) : (
              <div onClick={handleConnectWallet} className="login-button">
                Connect
              </div>
            )}
            <div
              onClick={() => setIsMobileSideMenuOpen(true)}
              className="mobile-side"
            >
              <HamburgerIcon className="mobile-side-icon" />
            </div>
          </div>
        </div>
        <div className="navbar-bottom">
          <div className="allowlist-game">ALLOWLIST GAME</div>
          <div className="eth-simulator">P&L TRACKER</div>
          <div className="leaderboard-title-header">WINNER LEADERBOARD</div>
          {address && (
            <div className="user-table-info-wrapper">
              <div className="user-table-info-highlight" />
              <div className="user-table-info">
                <div className="user-table-left">
                  <div className="user-table-left-top">YOUR STATS</div>
                  <div className="user-table-left-bottom">
                    <div className="user-name-wallet">
                      {truncateWallet(address)}
                    </div>
                  </div>
                </div>
                <div className="user-table-right">
                  <div className="user-table-right-top">
                    <div className="user-table-lost">Total Revenue</div>
                  </div>
                  <div className="user-table-right-bottom">
                    <div className="loss">
                      {loading && !userdata?.Pnlvalid ? (
                        <> ... </>
                      ) : (
                        <>
                          {userdata?.totalRevenue == null
                            ? 0
                            : parseFloat(userdata?.totalRevenue).toFixed(2) ===
                              "-0.00"
                            ? "< -0.01"
                            : parseFloat(userdata?.totalRevenue).toFixed(
                                2
                              )}{" "}
                          ETH
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="navbar-bottom-gradient" />
        </div>
      </div>
    </>
  );
};

export default NavBar;
