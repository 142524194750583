import React, { useContext, useState, useEffect } from "react";
import truncateWallet from "../../utils/truncateWallet";
import { LeaderboardContext } from "../../provider/leaderboard";
// import { toast } from "react-toastify";
import "./index.scss";
import { notifySuccess } from "../../utils/function";
import { ReactComponent as AllowedIcon } from "../../assets/media/icons/Allowed.svg";

import Pagination from "../../pages/leaderboard/Pagination";
const ThisWeekResult = ({ weektype, result, title, loading, winner }) => {
  const { searchInput, winnerCount } = useContext(LeaderboardContext);

  // const [winners, setWinners] = useState([]);
  const [users, setUsers] = useState([]);

  const winnerClassName = "weekly-canditate-wrapper winner";
  const nonWinnerClassName = "weekly-canditate-wrapper non-winner";

  // rateLostRaise function that calculates the rate of lost or raise
  const rateLostRaise = (prev, current) => {
    const rate = (-(prev - current) / prev) * 100;
    if (rate < 0) return `${rate.toFixed(2)}%`;
    if (rate > 0) return `${rate.toFixed(2)}%`;
    if (rate === 0) return "0.00%";
  };

  // lostOrRaise function that checks if the rate is positive or negative
  const lostOrRaise = (prev, current) => {
    const rate = ((prev - current) / prev) * 100;
    if (rate < 0) return "green";
    if (rate > 0) return "red";
    if (rate === 0) return "white";
  };

  // rankCheck function that checks the rank of the user and returns the corresponding class
  const rankCheck = (index) => {
    switch (index) {
      case 0:
        return "weekly-rank one";
      case 1:
        return "weekly-rank two";
      case 2:
        return "weekly-rank three";
      default:
        return "weekly-rank";
    }
  };

  useEffect(() => {
    if (result?.user_list) {
      const usersWithRank = result.user_list.map((user, index) => ({
        ...user,
        absoluteRank: index + 1,
      }));
      setUsers(usersWithRank);
    }
  }, [result]);

  useEffect(() => {
    setUsers(result?.user_list);

    console.log(users);
  }, [result]);

  useEffect(() => {
    if (searchInput) {
      const filteredUsers = users.filter((user) =>
        user.walletAddress.toLowerCase().includes(searchInput.toLowerCase())
      );
      setUsers(filteredUsers);
    } else {
      if (result?.user_list) {
        const usersWithRank = result.user_list.map((user, index) => ({
          ...user,
          absoluteRank: index + 1,
        }));

        setUsers(usersWithRank);
      }
    }
  }, [searchInput, result]);

  // useEffect(() => {
  //   if (users?.length > 0) {
  //     // Sort the users based on their score in descending order
  //     const sortedUsers = users?.sort((a, b) => a.lose - b.lose);
  //     // Take the top 100 users
  //     const top100 = sortedUsers.slice(0, 3);
  //     setWinners(top100);
  //   }
  // }, [users]);

  const copyHandler = (walletAdress) => {
    navigator.clipboard.writeText(walletAdress);
    notifySuccess("Wallet address copied to clipboard.");
  };

  return (
    <>
      {loading ? (
        <>
          <div className="no-result">
            <p>Loading...</p>
          </div>
        </>
      ) : users?.length > 0 ? (
        users?.map((el, index) => {
          const userrank =
            el.absoluteRank +
            (result?.pagination?.currentPage - 1) *
              result?.pagination?.itemsPerPage;

          const isHighlighted = userrank <= winnerCount;

          return (
            <div
              onClick={() => copyHandler(el.walletAddress)}
              className={
                isHighlighted && result?.pagination?.currentPage === 1
                  ? winnerClassName
                  : nonWinnerClassName
              }
              key={index}
            >
              <div className="weekly-candidate-left-side">
                <div className={rankCheck(userrank)}>
                  {isHighlighted && result?.pagination?.currentPage === 1 && (
                    <AllowedIcon />
                  )}
                  <div className="weekly-rank-number">{userrank}</div>
                </div>
                <div className="weekly-canditate-username">
                  {/*  */}
                  {!el.ens
                    ? el.walletAddress.length < 10
                      ? el.walletAddress
                      : truncateWallet(el.walletAddress)
                    : el.ens}
                </div>
              </div>

              <div className="weekly-candidate-right-side">
                <div className="weekly-candidate-lostgainedamount">
                  <span>
                    {el.totalRevenue == null
                      ? 0
                      : parseFloat(el.totalRevenue).toFixed(2) === "-0.00"
                      ? "< -0.01"
                      : parseFloat(el.totalRevenue).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-result">
          <p>No results...</p>
        </div>
      )}

      {users?.length > 0 && (
        <Pagination weektype="current" Paginationdata={result?.pagination} />
      )}
    </>
  );
};

export default ThisWeekResult;
